<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/welcome' }" v-if="$_isAdmin()">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/dashboard' }" v-else>首页</el-breadcrumb-item>
            
            <el-breadcrumb-item>修改密码</el-breadcrumb-item>
            
        </el-breadcrumb>

        <el-card class="box-card">
        <div class="text item">
            <el-form ref="loginForm1Ref" :model="loginForm1" :rules="loginForm1Rules"   label-width="100px" class="login_form">
                <!--prefix-icon="iconfont icon-xingmingyonghumingnicheng"-->
                <el-form-item prop="username" label="登录账号">
                    <el-input v-model="loginForm1.username" :disabled="true"></el-input>
                    
                </el-form-item>
                <el-form-item prop="oldPwd" label="原密码">
                    <el-input v-model="loginForm1.oldPwd" type="password" placeholder="请输入原密码"></el-input>
                </el-form-item>
                <el-form-item prop="newPwd" label="新密码">
                    <el-input v-model="loginForm1.newPwd" type="password" placeholder="请输入新密码"></el-input>
                </el-form-item>
                <el-form-item prop="pwd" label="确认新密码">
                    <el-input v-model="loginForm1.pwd" type="password" placeholder="请再次输入新密码"></el-input>
                </el-form-item>

                <el-form-item label="id" prop="id" v-show="false" hidden="true">
                    {{loginForm1.id}}
                </el-form-item>

                <el-form-item class="btns">
                        <el-button type="primary" @click="updatepass">确认</el-button>
                </el-form-item>
            </el-form>
        </div>
        </el-card>

    </div>
</template>

<script>
export default {
    data(){
        var validatePhone = (rule,value,callback)=>{
            if(value == ''){
                callback(new Error('请输入手机号'))
            }else{
                if(value !==''){
                    var reg = /^1[13456789]\d{9}$/;
                    if(!reg.test(value)){
                        callback(new Error('手机号输入错误'))
                        this.codeDisabled = true;
                    }else{
                        //手机号码输入正确时才可点击获取验证码按钮
                        this.codeDisabled = false;
                    }
                }
                callback();
            }
                
        }
        var validatePass2 = (rule, value, callback) => {
          if (value === '') {
              callback(new Error('请再次输入密码'));
          } else if (value !== this.loginForm1.newPwd) {
              callback(new Error('两次输入密码不一致!'));
          } else {
              callback();
          }
        }
        return{
            //这是登录表单的数据绑定对象
            loginForm1:{
                id:'',
                username:'',
                oldPwd:'',
                newPwd:'',
                pwd:'',
            },
            //验证规则
            loginForm1Rules:{
                
                // phone:[
                //     {validator:validatePhone,trigger:"change"}
                // ],
                // captcha:[
                //     { required: true, message: '请输入验证码', trigger: 'blur' },
                //     // { min: 6, max: 6, message: '长度为6位数字', trigger: 'blur' },
                //     // { type: 'number', message: '长度为6位数字', trigger: 'blur' }
                // ],
                oldPwd:[
                    { required: true, message: '请输入原密码', trigger: 'blur' },
                    { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
                ],
                newPwd:[
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' },
                    // { validator: validatePass2, trigger: 'blur', required: true }
                ],
                pwd:[
                    { required: true, message: '请再次输入新密码', trigger: 'blur' },
                    { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' },
                    { validator: validatePass2, trigger: 'blur' }
                ]
            },


        };
    },
    created(){
        this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
        this.loginForm1.id = this.userInfo.id
        this.loginForm1.username = this.userInfo.username
    },
    methods:{
       
       updatepass(){
           this.$refs.loginForm1Ref.validate(async valid=>{
               //console.log(valid)
               if(!valid) return;
               const {data:res} = await this.$http.put("sys/user/changePassword",{oldPwd:this.loginForm1.oldPwd,newPwd:this.loginForm1.newPwd,pwd:this.loginForm1.pwd});
                console.log("修改密码成功之前");
               console.log(res);//如果某个方法返回promise，可以用 await async 简化 
               if(res.code !== 200) return this.$message.error(res.message);
               this.$message.success("修改密码成功");
               //将登录成功之后的token，保存到客户端的sessionStroage中
               //项目中除了登录之外的其他api接口，必须在登录之后才能访问
               //token只应在当前网站打开期间生效，所以token保存在sessionStroage中
               console.log("修改密码成功之后");
               //console.log('找回密码页面的token'+res.result.token);
            //    window.sessionStorage.setItem('token',res.result.token);//浏览器工具 application -- Storage --  sessionStorage
            //    window.sessionStorage.setItem('uid',res.result.userInfo.id);
            //    window.sessionStorage.setItem('userInfo',JSON.stringify(res.result.userInfo));
               //通过编程式导航跳转到后台主页，路由地址是 ／home
               const {data:res2} = await this.$http.post("sys/logout");
                console.log(res2);
                if(res2.code !== 200) return this.$message.error(res2.message);
                window.sessionStorage.clear();
                this.$router.push('/');

           })
       },
       

    }

};//分号一定要写
</script> 

<style lang="less" scoped>
  
</style>